<template>
  <v-footer
    absolute
    class="justify-space-between pt-12 mt-12"
    color="transparent"
    height="auto"
  >
    <v-row align="center" justify="space-between">
      <v-col class="text-center text-md-left" cols="12" md="5">
        <v-btn
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
          class="mx-auto hidden-sm-and-down"
          small
          text
        >
          {{ item.text }}
        </v-btn>
      </v-col>

      <v-col
        class="pb-2 pt-3 text-center mx-auto hidden-sm-and-down"
        cols="12"
        md="2"
        order="1"
        order-sm="0"
      >
        &copy; {{ new Date().getFullYear() }} Twin Streams. All rights reserved.
      </v-col>

      <v-col class="text-center text-md-right" cols="12" md="5">
        <!-- <p>Made with 💜 by Jamie Seymour</p> -->
        <v-btn
          v-for="([href, text, name], i) in socials"
          :key="`social-${i}`"
          :href="href"
          class="px-0 mx-2"
          color="grey darken-1"
          text
          small
          light
          min-width="48"
          rel="noopener"
          target="_blank"
        >
          <v-icon class="mr-2" v-text="text" />
          <span class="hidden-sm-and-down" v-text="name" />
        </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<style scoped></style>

<script>
// Utilities
import { mapState } from "vuex";

export default {
  name: "CoreFooter",

  data: () => ({
    items: [
      {
        to: "/",
        text: "Home"
      },
      {
        to: "/nursery",
        text: "Nursery"
      },
      {
        to: "/consulting",
        text: "Consulting"
      },
      {
        to: "/landscaping",
        text: "Landscaping"
      },
      {
        to: "/contact",
        text: "Contact"
      }
    ],
    socials: [
      [
        "https://www.facebook.com/Twinstreamsindigenousnursery",
        "mdi-facebook",
        "Facebook"
      ],

      [
        "https://www.instagram.com/twinstreams_nursery/",
        "mdi-instagram",
        "Instagram"
      ]
    ]
  }),

  computed: {
    ...mapState("app", ["links"]),
    categories() {
      return [];
    }
  }
};
</script>
